<template>
  <div class="deviceStatus">
    <div class="statusItem" v-for="item in status" :key="item.className">
      <div :class="item.className"></div>
      <div class="statusDetail">
        <div class="statusName">{{ item.name }}</div>
        <div class="statusValue" v-if="deviceData">
          <span class="value">{{ deviceData[item.code] || 0 }}</span>
          {{ unit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deviceData: {
      type: Object
    }
  },
  data() {
    return {
      unit: '台',
      status: [
        { name: '运行设备', className: 'running', code: 'runningDeviceSize' },
        { name: '空闲设备', className: 'free', code: 'freeDeviceSize' },
        { name: '故障设备', className: 'Alarm', code: 'alarmDeviceSize' },
        { name: '离线设备', className: 'offline', code: 'offlineDeviceSize' }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.deviceStatus {
  padding-left: 20px;
  display: flex;
  .statusItem {
    display: flex;
    width: 160px;
    height: 60px;
    .statusDetail {
      padding-left: 10px;
      .statusName {
        font-family: stand;
        color: #f3f3f3;
        font-size: 16px;
      }
      .statusValue {
        font-size: 10px;
        line-height: 30px;
        color: #f3f3f3;
        & > .value {
          font-size: 28px;
          font-family: MyFontName;
        }
      }
    }
    .running {
      width: 60px;
      height: 52px;
      background: url('../assets/running.png') no-repeat;
      background-position: left center;
    }
    .free {
      width: 60px;
      height: 52px;
      background: url('../assets/free.png') no-repeat;
      background-position: left center;
    }
    .Alarm {
      width: 60px;
      height: 52px;
      background: url('../assets/Alarm.png') no-repeat;
      background-position: left center;
    }
    .offline {
      width: 60px;
      height: 52px;
      background: url('../assets/offline.png') no-repeat;
      background-position: left center;
    }
  }
}
</style>
