<template>
  <!-- 头部控制 -->
  <div class="header">
    <div class="title">
      <div class="timer">
        <span class="hour">
          {{ hour }}
        </span>
        <span class="day">
          {{ day }}
        </span>
      </div>
      <div class="titleText" :style="!isMapShow ? 'cursor: pointer;' : ''" @dblclick.stop="changeTitle()">{{ form.title }}</div>
      <div class="btnGroup">
        <div class="btn" :title="name" v-for="{ icon, name, event } in btnGroup" :key="icon">
          <i :class="icon" @click="event()"></i>
        </div>
      </div>
    </div>
    <!-- 设备状态 -->
    <DeviceStatus v-if="isMapShow" :deviceData="deviceData" />
    <slot></slot>
    <Dialog ref="NameDialog" :title="dialogTitle" @confirm="confirm">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="地图名称">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { timerFormatter } from '@/axios/utils.js';
import DeviceStatus from '../deviceStatus';
import Dialog from '../dialog';
export default {
  props: {
    // 是否为展示界面
    isMapShow: {
      type: Boolean,
      default: false
    },
    /***
     * 右上角按钮集合 example
     * btnGroup:[
     *  {
     *    icon:'icon',图标
     *    name:'name',名字
     *    event:this.event 事件处理函数
     *  }
     * ]
     */
    btnGroup: {
      type: Array,
      default: () => []
    },
    MapData: {
      type: Object,
      default: null
    }
  },
  components: { DeviceStatus, Dialog },
  watch: {
    async MapData() {
      this.form.title = this.MapData.name;
      const deviceData = await this.$apis.Map3d.deviceData({ bindDevices: this.MapData.bindDevices });
      this.deviceData = deviceData;
    },
    deep: true
  },
  data() {
    return {
      hour: timerFormatter('hh:mm:ss', new Date()),
      day: timerFormatter('yyyy-MM-dd', new Date()),
      timer: null,
      deviceData: null,
      form: { title: '设备可视化电子地图' },
      dialogTitle: '重命名',
      isDeviceTimer: true
    };
  },
  async mounted() {
    // 首次调用
    if (this.isMapShow) {
      const deviceData = await this.$apis.Map3d.deviceData({ bindDevices: this.MapData.bindDevices });
      this.deviceData = deviceData;
      this.init();
    }
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      // 时间定时器
      this.timer = setInterval(() => {
        this.hour = timerFormatter('hh:mm:ss', new Date());
        this.day = timerFormatter('yyyy-MM-dd', new Date());
        // 判断是否展示 阀门是否开启
        if (this.isMapShow && this.deviceTimer) {
          setTimeout(async () => {
            const deviceData = await this.$apis.Map3d.deviceData({ bindDevices: this.MapData.bindDevices });
            this.deviceData = deviceData;
            // 阀门打开
            this.deviceTimer = true;
          }, 9 * 1000);
        }
        // 阀门关闭
        this.deviceTimer = false;
      }, 1000);
    },
    changeTitle() {
      if (this.isMapShow) {
        return false;
      }
      this.$refs.NameDialog.show();
    },
    confirm() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const name = this.form.title;
      this.$apis.Map3d.changeTitle({ tenantId, name });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  color: #ffffff;
  height: 146px;
  .title {
    display: flex;
    justify-content: space-between;
    height: 61px;
    width: 100%;
    background: url('../assets/header.png') no-repeat center center;
    background-size: cover;
    .titleText {
      font-size: 34px;
      color: #b0d7ff;
      width: 34%;
      text-align: center;
    }
    .btnGroup {
      display: flex;
      justify-content: right;
      padding-top: 10px;
      width: 30%;
      .btn {
        width: 40px;
        i {
          cursor: pointer;
        }
      }
    }
    .timer {
      width: 30%;
      padding-top: 8px;
      .hour {
        font-size: 22px;
      }
      .day {
        font-size: 14px;
      }
    }
  }
}
/deep/.el-form-item__label {
  color: #fff;
}
/deep/ .el-input__inner {
  width: 400px;
}
</style>
