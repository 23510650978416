<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <slot></slot>
    <div v-if="content" class="hint">
      <div class="icon" :style="type === 'warning' ? 'margin-bottom: 22px;' : ''">
        <i class="iconfont mapIcon" :class="type === 'warning' ? 'icon-dcjingshi' : 'icon-dcshifoushanchu'"></i>
      </div>
      <div class="content">
        {{ content }}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  // dialog提示内容----content
  // dialog Icon类型----type
  // dialog 标题----title
  props: ['title', 'type', 'content'],
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.$emit('clearContent');
      this.dialogVisible = false;
    },
    confirm() {
      this.$emit('confirm');
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.hint {
  padding: 0 40px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mapIcon {
  color: #1b858f;
  font-size: 50px;
}
/deep/.el-dialog {
  margin-top: 23vh !important;
  background-color: #0b151d;
  border-radius: 10px;
}
/deep/ .el-dialog__title {
  font-family: stand;
  color: #fff !important;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #1b3d42;
}
/deep/ .el-icon-close::before {
  font-size: 18px !important;
  color: #fff !important;
}
/deep/ .el-button--primary {
  width: 90px;
  height: 36px;
  background-color: #1b858f !important;
  border: 1px solid #29f5ff;
}
/deep/ .el-button--default {
  width: 90px;
  height: 36px;
  color: #bdbdbd;
  background-color: #0a161b !important;
  border: 1px solid #bdbdbd;
}
/deep/ .dialog-footer > .el-button--default:hover {
  width: 90px;
  height: 36px;
  color: #bdbdbd;
  background-color: #0a161b !important;
  border: 1px solid #bdbdbd;
}
.content {
  margin: 40px 10px;
  font-size: 18px;
  text-align: center;
  font-family: stand;
  color: #fff;
}
</style>
